import * as yup from "yup";
import { useFormContext } from "react-hook-form";
import { TextField } from "@/components/Forms";
import { WhatsappUser } from "@/models/auth";

export const BusinessDetailsForm = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext<Pick<WhatsappUser, "managementId" | "managementToken">>();
  return (
    <div className="flex flex-col space-y-4">
      <TextField
        placeholder="Business System User Token"
        name="managementToken"
        register={register}
        error={errors.managementToken?.message}
      />
      <TextField
        placeholder="Business Phone Number ID"
        name="managementId"
        register={register}
        error={errors.managementId?.message}
      />
    </div>
  );
};

export const BusinessDetailsFormValidations = {
  managementToken: yup.string().required("User token is required"),
  managementId: yup.string().required("Phone Number ID is required")
};
