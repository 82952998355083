/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Toggle } from "@/components/Forms";
import { Category } from "@/models/categories";
import { useHideCategory, useUpdateCategory } from "../hooks/useCategories";
import { categoryEditeModal } from "./CategoryEditeModal";
import { deleteCard } from "./DeleteWarning";

type CatCard = {
  category: Category;
  onDelete: Function;
  update: Function;
};

export const CategoryCard = ({ category, onDelete, update }: CatCard) => {
  const { mutate: hideCategory, data } = useHideCategory();
  const { mutate: updateCategory } = useUpdateCategory();
  const { register, setValue } = useForm<{ hidden: boolean }>({
    defaultValues: { hidden: !category?.hidden }
  });
  if (data) setValue("hidden", !data.hidden);

  useEffect(() => {
    setValue("hidden", !category.hidden);
  }, [category]);

  const navigate = useNavigate();
  const onUpdate = () => (data: any) => {
    updateCategory(data, {
      onSuccess() {
        update();
      }
    });
  };
  const handleDelete = () => {
    deleteCard({
      cardName: category.name,
      accept: () => {
        onDelete(category._id);
      }
    });
  };
  return (
    <div className="card  w-52 bg-base-100 shadow-xl">
      <figure onClick={() => navigate(`../catalog/${category._id}`)}>
        {category.images.length ? (
          <img className=" cursor-default" src={category.images[0]} alt={category.name} />
        ) : (
          <img
            className=" cursor-pointer"
            src="https://i.imgur.com/82iBhjt.png"
            alt={category.name}
          />
        )}
      </figure>
      <div className="card-body ">
        <div
          onClick={() => navigate(`../catalog/${category._id}`)}
          className="card-title cursor-pointer justify-center mb-3 "
        >
          {category.name}
        </div>

        <div className="card-actions ">
          <div className="flex gap-5">
            <img
              className=" cursor-pointer"
              onClick={() => handleDelete()}
              src="/img/icons/delete.svg"
              alt="delete"
            />
            <div className=" ml-auto">
              <Toggle
                name="hidden"
                register={register}
                onClick={() => hideCategory(category._id)}
              />
              <div
                id="tooltip-default"
                role="tooltip"
                className="absolute z-10   invisible px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm  tooltip dark:bg-gray-700"
              >
                Tooltip content
                <div className="tooltip-arrow" data-popper-arrow />
              </div>
            </div>
            <img
              className=" cursor-pointer"
              onClick={() => {
                categoryEditeModal({
                  update: onUpdate(),
                  category
                });
              }}
              src="/img/icons/edit.svg"
              alt="delete"
            />
          </div>
          {/* category.parent && <div className="badge badge-outline mt-4">{parent?.name}</div> */}
        </div>
      </div>
    </div>
  );
};
