/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Toggle } from "@/components/Forms";
import { Product } from "@/models/Products";
import { productEditeModal } from "./ProductEditeModal";
import { useHideProduct, useUpdateProduct } from "../hooks";
import { deleteCard } from "./DeleteWarning";

type ProdCard = {
  product: Product;
  onDelete: Function;
  update: Function;
};
export const ProductCard = ({ product, onDelete, update }: ProdCard) => {
  const { mutate: hideProduct, data } = useHideProduct();
  const { mutate: updateProduct } = useUpdateProduct();

  const { register, setValue } = useForm<{ hidden: boolean }>({
    defaultValues: { hidden: !product?.hidden }
  });

  if (data) setValue("hidden", !data.hidden);

  useEffect(() => {
    setValue("hidden", !product.hidden);
  }, [product]);

  const handleDelete = () => {
    deleteCard({
      cardName: product.name,
      accept: () => {
        onDelete(product._id);
      }
    });
  };

  const onUpdate = () => (data: any) => {
    updateProduct(data, {
      onSuccess() {
        update();
      }
    });
  };
  return (
    <div className="card  w-52 bg-base-100 shadow-xl">
      <figure>
        <Link to={`../catalog/products/${product._id}`} className=" no-underline">
          {product.images.length > 0 ? (
            <img src={product.images[0]} alt={product.name} />
          ) : product.thumbnail ? (
            <img src={product.thumbnail} alt={product.name} />
          ) : (
            <img src="https://i.imgur.com/82iBhjt.png" alt={product.name} />
          )}
        </Link>
      </figure>
      <div className="card-body ">
        <Link to={`../catalog/products/${product._id}`} className=" no-underline">
          <div className="card-title justify-start ">{product.name}</div>
          <div className=" text-sm justify-start mb-8 text-gray-400">{product.price} SDG</div>
        </Link>

        <div className="card-actions ">
          <div className="flex gap-5">
            <img
              className=" cursor-pointer"
              onClick={handleDelete}
              src="/img/icons/delete.svg"
              alt="delete"
            />
            <div className=" ml-auto">
              <Toggle name="hidden" register={register} onClick={() => hideProduct(product._id)} />
              <div
                id="tooltip-default"
                role="tooltip"
                className="absolute z-10   invisible px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm  tooltip dark:bg-gray-700"
              >
                Tooltip content
                <div className="tooltip-arrow" data-popper-arrow />
              </div>
            </div>
            <img
              className=" cursor-pointer"
              onClick={() => {
                productEditeModal({
                  product,
                  update: onUpdate()
                });
              }}
              src="/img/icons/edit.svg"
              alt="delete"
            />
          </div>
          {/* category.parent && <div className="badge badge-outline mt-4">{parent?.name}</div> */}
        </div>
      </div>
    </div>
  );
};
