import { useRef } from "react";
import * as yup from "yup";
import { NodeProps } from "react-flow-renderer";
import { MediaNodeData } from "@/models/flow";
import { useOutsideAlerter } from "@/hooks";
import { deleteNodeDialog, editNodeDialog } from "../Dialogs";
import { ControlButton } from "./ControlButton";
import {
  useDeleteElement,
  useDisconnectNode,
  useUpdateElement,
  useUpdateElementMedia
} from "../../hooks/flow-hooks";
import { useExampleSets } from "../../hooks/example-set-hooks";
import styles from "./node.module.css";

type _BaseNodeProps = Partial<NodeProps> & {
  title: string;
  color: string;
  icon?: string;
  form?: JSX.Element;
  validationObject?: Record<string, yup.AnySchema>;
  isDragElement?: boolean;
};

export const BaseNode = ({
  title,
  color,
  icon,
  type,
  form,
  data,
  id: nodeId,
  validationObject,
  isDragElement
}: _BaseNodeProps) => {
  const controlsRef = useRef(null);
  const { show, setShow } = useOutsideAlerter(controlsRef, false);

  // Each node hase 3 actions (Delete | Diconnect | Update)
  const { mutate: removeNode } = useDeleteElement();
  const { mutate: disconnectNode } = useDisconnectNode();
  const { mutate: updateElement, isLoading: isUpdatingElement } = useUpdateElement();
  const { mutate: updateElementMedia, isLoading: isUpdatingElementMedia } = useUpdateElementMedia();

  const isUpdating = isUpdatingElement || isUpdatingElementMedia;
  const { data: sets } = useExampleSets();

  return (
    <div className={`${styles.node}`} style={{ backgroundColor: color }}>
      <div className="flex justify-center items-center space-x-6">
        <div className="flex space-x-2 items-center">
          {!isDragElement && <img className="w-4" src={`/img/flow-icons/${icon}`} alt={title} />}
          <p style={{ fontSize: 14 }}>{title}</p>
        </div>
        {!isDragElement && !isUpdating && (
          // eslint-disable-next-line
          <img
            className="w-5 cursor-pointer"
            src="/img/flow-icons/circle-chevron-downword.svg"
            alt="arrow"
            style={{
              transform: !show ? "rotateX(0deg)" : "rotateX(180deg)"
            }}
            onClick={(e) => {
              e.preventDefault();
              setShow(!show);
            }}
          />
        )}
        {isUpdating && <div className="loader w-5 h-5 border-2" />}
      </div>
      <div
        className={`${show ? "block" : "hidden"} flex space-x-4 justify-center items-center mt-3`}
      >
        <ControlButton
          icon="link-slash-solid.svg"
          action={() => {
            setShow(false);
            disconnectNode(nodeId!);
          }}
        />

        <ControlButton
          icon="edit-pen-solid.svg"
          action={() => {
            if (form)
              editNodeDialog({
                type,
                form,
                initialData: data,
                validationObject,
                sets: sets ?? [],
                save: (data) => {
                  setShow(false);
                  if (nodeId) {
                    updateElement({ nodeId, data });

                    const { mediaType, media, url } = data as MediaNodeData;
                    if (media && !url) {
                      updateElementMedia({ nodeId, type: mediaType, media });
                    }
                  }
                }
              });
          }}
        />
        <ControlButton
          icon="trash-can-regular.svg"
          action={() => {
            deleteNodeDialog({
              nodeTitle: title,
              type,
              accept: () => {
                setShow(false);
                if (nodeId) removeNode(nodeId);
              }
            });
          }}
        />
      </div>
    </div>
  );
};
