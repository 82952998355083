import * as yup from "yup";
import { useFormContext } from "react-hook-form";
import { TextField } from "@/components/Forms";
import { WhatsappUser } from "@/models/auth";

export const CatalogIDForm = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext<Pick<WhatsappUser, "catalog_id">>();

  return (
    <TextField
      name="catalog_id"
      register={register}
      placeholder="Catalog ID"
      error={errors.catalog_id?.message}
    />
  );
};

export const CatalogIDFormValidations = {
  catalog_id: yup.string().required("Catalog ID is required")
};
