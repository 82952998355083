/* eslint-disable prefer-template */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { BreadCrumb } from "@/components/BreadCrumb";
import { useParams } from "react-router-dom";
import { productEditeModal, AnimatedProductDetails } from "./components";
import { useProductsById, useUpdateProduct } from "./hooks";

type Props = {
  prodId: string;
};

export const ProductDetails = () => {
  const { prodId } = useParams<Props>();
  const { isLoading, data: product, refetch } = useProductsById(prodId!);
  const { mutate: updateProduct } = useUpdateProduct();
  const onUpdate = () => (data: any) => {
    updateProduct(data, {
      onSuccess() {
        refetch();
      }
    });
  };

  return (
    <>
      <BreadCrumb
        items={[
          {
            text: "Catalog",
            isActive: false,
            path: "/catalog"
          },
          {
            text: product?.categories[0].name,
            isActive: false,
            path: `/catalog/${product?.categories[0]._id}`
          },
          {
            text: product?.name!,
            isActive: true
          }
        ]}
      />
      {isLoading ? (
        <AnimatedProductDetails />
      ) : (
        <section className="text-gray-700 body-font overflow-hidden  mt-10">
          <div className="container px-5 py-24 mx-auto">
            <div className="lg:w-4/5 mx-auto flex flex-wrap">
              <div className="lg:w-1/2 w-full object-cover object-center rounded border border-gray-200">
                <div className="carousel w-full">
                  {product?.images.length !== 0 &&
                    product?.images.map((img) => (
                      <div id={img} key={img} className="carousel-item w-full">
                        <img alt={product.name} src={img} className="w-full" />
                      </div>
                    ))}
                </div>
                <div className="flex justify-center w-full py-2 gap-2">
                  {product?.images.map((img, index) => {
                    const h = `#${img}`;
                    return (
                      <a key={img} href={h} className="btn btn-xs">
                        {index + 1}
                      </a>
                    );
                  })}
                  {!product?.images.length && (
                    <img
                      alt={product?.name}
                      src="https://i.imgur.com/82iBhjt.png"
                      className="w-96"
                    />
                  )}
                </div>
              </div>
              <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
                <h1 className="text-gray-900 text-3xl title-font font-medium mb-1">
                  {product?.name}
                </h1>
                <div className="flex mb-4">
                  <span className="flex items-center">
                    <span className="text-gray-600 ml-3">{product?.ordersCount} Orders</span>
                  </span>
                  <span className="flex ml-3 pl-3 py-2 border-l-2 border-gray-200">
                    {Number(product?.income) / 1000} K(SDG) Total Income
                  </span>
                </div>
                <p className="leading-relaxed">{product?.desc}</p>
                <div className="flex mt-6 items-center pb-5 border-b-2 border-gray-200 mb-5">
                  <div className="flex ml-6 items-center">
                    <div className="relative">
                      <span className="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          className="w-4 h-4"
                          viewBox="0 0 24 24"
                        >
                          <path d="M6 9l6 6 6-6" />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <span className="title-font font-medium text-2xl text-gray-900">
                    {product?.price}SDG
                  </span>
                  <button
                    onClick={() => {
                      productEditeModal({
                        product: product!,
                        update: onUpdate()
                      });
                    }}
                    className="flex ml-auto text-white bg-blue-700 border-0 py-2 px-6 focus:outline-none hover:bg-blue-800 rounded"
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
