import { OrderProduct } from "@/models/orders";
import React from "react";

type Props = {
  orderProducts: OrderProduct[];
};

export const CheckoutCard = ({ orderProducts }: Props) => (
  <div className=" shadow-lg flex flex-col h-fit justify-start items-start bg-gray-50 px-4 py-4 md:py-6 md:p-6 xl:p-8 w-full">
    <p className="text-lg md:text-xl font-semibold leading-6 xl:leading-5 text-gray-800">
      Customer’s Cart
    </p>
    <div className="pt-5">
      {orderProducts?.map((p) => (
        <div
          key={p.name}
          className="mt-1 md:mt-1 flex  flex-col md:flex-row justify-start items-start md:items-center md:space-x-6 xl:space-x-8 w-full "
        >
          <div className="pb-4 md:pb-8 w-full md:w-40">
            {p.thumbnail ? (
              <img
                className="w-20 hidden rounded-xl md:block hover:scale-150"
                src={p.thumbnail}
                alt="img"
              />
            ) : (
              <img
                className="w-20 hidden rounded-xl md:block hover:scale-150"
                src="https://i.imgur.com/82iBhjt.png"
                alt={p.name}
              />
            )}
          </div>
          <div className="border-b border-gray-200 md:flex-row flex-col flex justify-between items-start w-full  pb-8 space-y-4 md:space-y-0">
            <div className="w-full flex flex-col justify-start items-start space-y-8">
              <h3 className="text-m xl:text-l font-semibold leading-6 text-gray-800">{p.name}</h3>
            </div>
            <div className="flex justify-between space-x-8 sm:space-x-20 items-start w-full">
              <p className="text-base leading-6">
                {p.purchasePrice} (SDG)
                {/* <span className="text-red-300 line-through"> $45.00</span> */}
              </p>
              <p className="text-base leading-6 text-gray-800">{p.amount}</p>
              <p className="text-base  font-semibold leading-6 text-gray-800">
                {p.amount * p.purchasePrice}(SDG)
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);
