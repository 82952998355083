type _PlatformItem = {
  name: string;
  isConnected?: boolean;
};

type Props = {
  iconName: string;
  color: string;
  item: _PlatformItem;
  onClick: Function;
};

export const PlatformCard = ({ iconName, color, item: { name, isConnected }, onClick }: Props) => (
  // eslint-disable-next-line
  <div
    className="flex items-center space-x-4 h-full p-4 font-medium"
    style={{
      borderTop: "1px solid #ececec"
    }}
    onClick={() => onClick()}
  >
    <div
      className={`flex items-center space-x-3 w-full text-white font-medium rounded-lg text-sm px-3 py-2.5 ${
        !isConnected ? "cursor-pointer hover:opacity-90" : ""
      }`}
      style={{ backgroundColor: color }}
    >
      <img className="w-5" src={`/img/icons/${iconName}`} alt="facebook" />

      <p>{name?.toLocaleUpperCase()}</p>
    </div>
    {isConnected && (
      // eslint-disable-next-line
      <div className="cursor-pointer p-2 rounded-md hover:bg-red-100" onClick={() => onClick()}>
        <img className="w-7" src="/img/icons/link-red-slash-solid.svg" alt="delete-sentence-icon" />
      </div>
    )}
  </div>
);
