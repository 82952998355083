import { WhatsappUser } from "@/models/auth";
import { whatsappDialog } from "./WhatsappDialogs";
import {
  BusinessDetailsForm,
  BusinessDetailsFormValidations,
  CatalogIDForm,
  CatalogIDFormValidations
} from "./Forms";
import { useUpdateWhatsappUser } from "../hooks/platforms-hooks";

type _Props = {
  whatsUser: WhatsappUser;
};

export const WhatsappCard = ({ whatsUser }: _Props) => {
  const { id, catalog_id: catalogId } = whatsUser || { id: "0000", catalog_id: "0000" };
  const { mutate: updateWA } = useUpdateWhatsappUser();
  // eslint-disable-next-line
  return (
    <div
      className="flex items-center space-x-1 h-full p-4 font-medium"
      style={{
        borderTop: "1px solid #ececec"
      }}
    >
      <div className="flex items-center space-x-3 w-full text-white font-medium rounded-lg text-sm px-3 py-2.5 bg-[#22c197]">
        <img className="w-5" src="/img/icons/whatsapp-white-icon.svg" alt="whatsapp" />
        <div>
          <div className="flex space-x-1 justify-between">
            <p>Mobile Number - </p>
            <p>{id}</p>
          </div>
          {catalogId && (
            <div className="flex space-x-1 justify-between">
              <p>Catalog ID - </p>
              <p>{catalogId}</p>
            </div>
          )}
        </div>
      </div>

      {/* eslint-disable-next-line */}
      <div
        className="cursor-pointer p-2 rounded-md hover:bg-green-100"
        onClick={() => {
          whatsappDialog({
            form: <CatalogIDForm />,
            validationObject: CatalogIDFormValidations,
            submit: (data) => updateWA(data)
          });
        }}
      >
        <img className="w-7" src="/img/icons/green-cart.svg" alt="delete-sentence-icon" />
      </div>
      {/* eslint-disable-next-line */}
      <div
        className="cursor-pointer p-2 rounded-md hover:bg-green-100"
        onClick={() => {
          whatsappDialog({
            form: <BusinessDetailsForm />,
            validationObject: BusinessDetailsFormValidations,
            submit: (data) => updateWA(data)
          });
        }}
      >
        <img className="w-7" src="/img/icons/green-shop.svg" alt="delete-sentence-icon" />
      </div>
    </div>
  );
};
