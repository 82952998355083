/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from "react";
import { Product } from "@/models/Products";
import { Table } from "@/components/Tables/Table";
import { TableItem } from "@/components/Tables/components/TableItem";
import { BreadCrumb } from "@/components/BreadCrumb";

import { useAllProducts, useDeleteProduct, useHideProduct, useUpdateProduct } from "./hooks";
import { productEditeModal } from "../Catalogs/components/ProductEditeModal";
import { AnimatedProductsTable, Filter, ImportExportXLSX } from "./components";
import { deleteCard } from "../Catalogs/components/DeleteWarning";

export const Products = () => {
  const { mutate: hideProduct } = useHideProduct();
  const { data: prods, isLoading, refetch } = useAllProducts();
  const { mutate: updateProduct } = useUpdateProduct();
  const { mutate: deleteProduct } = useDeleteProduct();
  const [products, setProducts] = useState<Product[]>([]);
  const [skip, setSkip] = useState(0);
  const [count, setCount] = useState<number>(0);
  const [q, setQ] = useState("");
  const [by, setBy] = useState<"" | "price" | "income" | "ordersCount">("");

  useEffect(() => {
    setCount(prods?.length!);
    if (prods) {
      if (by === "")
        setProducts(
          Array.from(prods?.filter((p) => p.name.includes(q))!).filter(
            (p, index) => index >= skip && index < skip + 12
          )
        );

      if (by !== "")
        setProducts(
          Array.from(
            Array.from(prods?.filter((p) => p.name.includes(q))!).sort((a, b) =>
              a[by] < b[by] ? 1 : -1
            )
          ).filter((p, index) => index >= skip && index < skip + 12)
        );
    }
  }, [prods, skip, q, by]);

  return (
    <div className="">
      <BreadCrumb items={[{ text: "Products", isActive: true }]} />
      <div className="flex flex-row justify-center items-center">
        <Filter
          onChange={(query, by) => {
            setBy(by);
            setQ(query);
          }}
        />
        <div className="mr-10">
          <ImportExportXLSX products={prods!} onChange={() => refetch()} />
        </div>
      </div>

      {isLoading ? (
        <AnimatedProductsTable />
      ) : products.length === 0 ? (
        <div className=" mt-[200px] max-w-fit flex justify-center items-center bg-[#757687] p-4 rounded-3xl text-white m-auto h-8 font-medium ">
          No Products!
        </div>
      ) : (
        <>
          <Table headers={["Name", "Price", "Income", "Ordered", "Hide/Show", "Actions"]}>
            {products?.map((product, index) => (
              <tr
                className={`border-b border-gray-200  hover:bg-gray-100 ${
                  index % 2 === 0 ? "bg-gray-50" : ""
                } cursor-pointer`}
                key={product._id}
              >
                <TableItem>
                  <div className="flex items-center">
                    <div className="mr-2">
                      {product.images[0] ? (
                        <img
                          className="w-10 rounded-xl border-gray-200 border -m-1 transform hover:scale-150"
                          src={product.images[0]}
                          alt="."
                        />
                      ) : (
                        <img
                          alt={product?.name}
                          src="https://i.imgur.com/82iBhjt.png"
                          className="w-10 rounded-xl border-gray-200 border -m-1 transform hover:scale-125"
                        />
                      )}
                    </div>
                    <span className="font-medium">{product.name}</span>
                  </div>
                </TableItem>
                <TableItem>
                  <span className=" text-xs">{product.price / 1000} K (SDG)</span>
                </TableItem>
                <TableItem>
                  <span className=" text-xs"> {product.income / 1000} K (SDG)</span>
                </TableItem>
                <TableItem>
                  <span
                    className={`  py-1 px-3 rounded-full text-xs ${
                      product.ordersCount === 0
                        ? " text-yellow-500 bg-yellow-200"
                        : product.ordersCount <= 5
                        ? "text-green-600 bg-green-200"
                        : product.ordersCount <= 15
                        ? " text-blue-500 bg-blue-200"
                        : " text-purple-500 bg-purple-200"
                    } `}
                  >
                    <span className="flex-row flex">
                      {product.ordersCount} times
                      {product.ordersCount === 0 ? (
                        <img className="w-4 ml-auto" src="/img/product-icons/shield.svg" alt="." />
                      ) : product.ordersCount <= 5 ? (
                        <img className="w-4" src="/img/product-icons/thumb.svg" alt="." />
                      ) : product.ordersCount <= 15 ? (
                        <img className="w-4" src="/img/product-icons/sparkles.svg" alt="." />
                      ) : (
                        <img className="w-4" src="/img/product-icons/aircraft.svg" alt="." />
                      )}
                    </span>
                  </span>
                </TableItem>
                <TableItem>
                  <div className="flex ml-3 items-center ">
                    <div className="flex items-center">
                      <label htmlFor={product._id} className="relative cursor-pointer">
                        <input
                          id={product._id}
                          type="checkbox"
                          className="sr-only peer"
                          checked={!product.hidden}
                          onChange={() => {
                            hideProduct(product._id, {
                              onSuccess() {
                                refetch();
                              }
                            });
                          }}
                        />
                        <div className="w-11 h-6 bg-gray-200 rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600" />
                      </label>
                    </div>
                  </div>
                </TableItem>
                <TableItem>
                  <div className="flex item-center justify-center">
                    <div
                      onClick={() => {
                        productEditeModal({
                          product,
                          update: (data: any) => {
                            updateProduct(data, {
                              onSuccess() {
                                refetch();
                              }
                            });
                          }
                        });
                      }}
                      className="w-6 mr-2 transform hover:text-purple-500 hover:scale-110"
                    >
                      <img className=" cursor-pointer" src="/img/icons/edit.svg" alt="delete" />
                    </div>
                    <div
                      onClick={() => {
                        deleteCard({
                          cardName: product.name,
                          accept: () => {
                            deleteProduct(product._id, {
                              onSuccess() {
                                refetch();
                              }
                            });
                          }
                        });
                      }}
                      className="w-6 ml-6 transform hover:text-purple-500 hover:scale-110"
                    >
                      <img src="/img/product-icons/delete.svg" alt="." />
                    </div>
                  </div>
                </TableItem>
              </tr>
            ))}
          </Table>

          <div className="px-5 py-5 border-t flex flex-col xs:flex-row items-center xs:justify-between          ">
            <span className="text-xs xs:text-sm text-gray-900">
              Showing {skip + 1} to {skip + 12} of {count} Entries
            </span>
            <div className="inline-flex mt-2 xs:mt-0">
              <button
                onClick={() => {
                  if (skip > 0) {
                    setSkip(skip - 12);
                  }
                }}
                type="button"
                className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l"
              >
                Prev
              </button>
              <button
                onClick={() => {
                  if (skip + 12 < count!) {
                    setSkip(skip + 12);
                  }
                }}
                type="button"
                className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r"
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
