export const AnimatedCatalog = () => (
  <div className="flex flex-1 flex-wrap  gap-10 curs  ">
    <div className="flex flex-1 flex-wrap gap-5  w-4/5 h-fit mt-10 ">
      {[1, 2, 3, 4, 5, 6, 7, 8].map((a) => (
        <div key={a}>
          <div className="card  w-52 bg-base-100 shadow-xl">
            <figure>
              <img className=" cursor-pointer" src="https://i.imgur.com/82iBhjt.png" alt="." />{" "}
            </figure>
            <div className="card-body ">
              <p className="skeleton w-[150px] h-[10px] rounded-[2px]" />
              <div className="card-actions ">
                <div className="flex gap-5">
                  <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
                </div>
                {/* category.parent && <div className="badge badge-outline mt-4">{parent?.name}</div> */}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);
