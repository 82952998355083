export const AnimatedProductDetails = () => (
  <section className="text-gray-700 body-font overflow-hidden  mt-10">
    <div className="container px-5 py-24 mx-auto">
      <div className="lg:w-4/5 mx-auto flex flex-wrap">
        <div className="lg:w-1/2 w-full object-cover object-center rounded border border-gray-200">
          <div className="flex justify-center w-full py-2 gap-2">
            <img alt="." src="https://i.imgur.com/82iBhjt.png" className="w-96" />
          </div>
        </div>
        <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
          <h1 className="text-gray-900 text-3xl title-font font-medium mb-1">
            <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
          </h1>
          <div className="flex mb-4">
            <span className="flex items-center">
              <p className="skeleton w-[50px] h-[10px] rounded-[2px]" />
            </span>
            <span className="flex ml-3 pl-3 py-2 border-l-2 border-gray-200">
              <p className="skeleton w-[50px] h-[10px] rounded-[2px]" />
            </span>
          </div>
          <p className="skeleton w-[100px] h-[10px] rounded-[2px] ml-52" />
          <p className="skeleton w-[150px] h-[10px] rounded-[2px]" />
          <p className="skeleton w-[150px] h-[10px] rounded-[2px]" />

          <div className="flex mt-6 items-center pb-5 border-b-2 border-gray-200 mb-5">
            <div className="flex ml-6 items-center">
              <div className="relative">
                <span className="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    className="w-4 h-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M6 9l6 6 6-6" />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div className="flex">
            <span className="title-font font-medium text-2xl text-gray-900">
              <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
);
