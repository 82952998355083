/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Product } from "@/models/Products";
import { useState } from "react";

type ProdCard = {
  product: Product;
  onChange: (amount: number, id: string) => void;
  showInput: boolean;
  clicked: () => void;
  amount: number;
};
export const ShopProducts = ({ product, onChange, showInput, clicked, amount }: ProdCard) => {
  const [error, setError] = useState(false);
  return (
    <div className={`card w-44 bg-base-100 mt-10 shadow-xl ${showInput ? " scale-110" : ""}`}>
      <figure className=" cursor-pointer" onClick={clicked}>
        {product.images.length ? (
          <img src={product.images[0]} alt={product.name} />
        ) : (
          <img src="https://i.imgur.com/82iBhjt.png" alt={product.name} />
        )}
      </figure>
      <div className="card-body ">
        <div className="card-title justify-start ">{product.name}</div>
        <div className=" text-sm justify-start  text-gray-400">{product.price} SDG</div>
        <div className="card-actions ">
          {showInput && (
            <div className="flex ">
              <span className="text-sm mt-1">Amount </span>
              <input
                defaultValue={amount}
                type="number"
                className={` z-10 bg-white ml-3   text-gray-900 sm:text-xs rounded-lg   w-full p-1 focus:outline-none border-2  ${
                  error ? "border-red-400" : "ring-primary-800 border-primary-800"
                }`}
                onChange={(e) => {
                  setError(false);
                  const a = Number(e.target.value);
                  if (a < 1) {
                    setError(true);
                  } else {
                    setError(false);
                    onChange(a, product._id);
                    // setAmount(a);
                  }
                }}
              />
            </div>
          )}
          {/* category.parent && <div className="badge badge-outline mt-4">{parent?.name}</div> */}
        </div>
      </div>
    </div>
  );
};
