import * as yup from "yup";
import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { WhatsappUser } from "@/models/auth";
import { BaseDialog } from "@/components/Modals";

type _WhatsappDialogProps = {
  show: boolean;
  form: JSX.Element;
  validationObject?: Record<string, yup.AnySchema>;
  onSubmit: (data: Partial<WhatsappUser>) => void;
  onCancel: () => void;
  onClose?: () => void;
  wrapperId?: string;
};

export const WhatsappDialog = ({
  show,
  form,
  validationObject,
  onSubmit,
  onCancel,
  onClose,
  ...rest
}: _WhatsappDialogProps) => {
  const [visible, setVisible] = useState(show);

  const methods = useForm<Partial<WhatsappUser>>({
    resolver: yupResolver(yup.object().shape({ ...validationObject }))
  });

  const { handleSubmit } = methods;

  const close = () => {
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
    setVisible(false);
  };

  useEffect(() => {
    if (show && !visible) setVisible(true);
  }, [show]);

  return (
    <BaseDialog
      title="Connect Catalog to Whatsapp"
      show={visible}
      body={
        <FormProvider {...methods}>
          <div className="px-5">{form}</div>
        </FormProvider>
      }
      action={
        <div className="flex justify-center items-center p-[15px] space-x-2">
          {/* eslint-disable-next-line */}
          <div
            role="button"
            className="text-gray-500 bg-white hover:bg-gray-100 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900"
            onClick={() => {
              onCancel();
              close();
            }}
          >
            Cancel
          </div>
          {/* eslint-disable-next-line */}
          <div
            role="button"
            className="text-white bg-[#044CAC] hover:bg-[#00328C] font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
            onClick={() => handleSubmit(onSubmit)()}
          >
            Submit
          </div>
        </div>
      }
      onClose={() => {
        close();
      }}
      {...rest}
    />
  );
};

export const whatsappDialog = ({
  form,
  validationObject,
  submit,
  cancel
}: {
  form: JSX.Element;
  validationObject?: Record<string, yup.AnySchema>;
  submit: (data: Partial<WhatsappUser>) => void;
  cancel?: () => void;
}) => {
  const wrapper = document.body.appendChild(document.createElement("div"));
  wrapper.id = "whatsapp-connection-dialog";

  const root = createRoot(wrapper);

  const promise = new Promise<Boolean>((resolve) => {
    try {
      root.render(
        <WhatsappDialog
          show
          form={form}
          validationObject={validationObject}
          onSubmit={(data) => {
            submit(data);
            resolve(true);
          }}
          onCancel={() => {
            // eslint-disable-next-line no-unused-expressions
            cancel && cancel();
            resolve(false);
          }}
          onClose={() => {
            // eslint-disable-next-line no-unused-expressions
            cancel && cancel();
            resolve(false);
          }}
          wrapperId={wrapper.id}
        />
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  });

  function dispose() {
    setTimeout(() => {
      root.unmount();
      setTimeout(() => {
        if (wrapper && wrapper.parentNode) {
          wrapper.parentNode.removeChild(wrapper);
        }
      });
    }, 600);
  }

  return promise.then(
    (result) => {
      dispose();
      return result;
    },
    (result) => {
      dispose();
      return Promise.reject(result);
    }
  );
};
