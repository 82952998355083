import { useRef } from "react";
import { useAppSelector, useOutsideAlerter } from "@/hooks";
import { PlatformCard } from "./PlatformCard";
import {
  useFacebook,
  useFacebookDisconnect,
  useInstagram,
  useInstagramDisconnect
} from "./hooks/platforms-hooks";
import { WhatsappCard } from "./WhatsappConnection/WhatsappCard";
import styles from "./platforms-menu.module.css";

export const PlatformsMenu = () => {
  const menuRef = useRef(null);
  const { show, setShow } = useOutsideAlerter<HTMLDivElement>(menuRef, false);

  const { facebook, instagram, whatsapp } = useAppSelector((state) => state.auth.user!);

  const { connect: connectToFacebook } = useFacebook();
  const { connect: connectToInstagram } = useInstagram();
  const { mutate: disconnectFacebook } = useFacebookDisconnect();
  const { mutate: disconnectInstagram } = useInstagramDisconnect();
  return (
    <div ref={menuRef}>
      {/* eslint-disable-next-line */}
      <div className="cursor-pointer" onClick={() => setShow(!show)}>
        <img className="w-7" src="/img/icons/social-media-icon.svg" alt="social_pages" />
      </div>
      <div className={`${show ? "block" : "hidden"} ${styles.platfroms_menu_container}`}>
        <PlatformCard
          item={{
            name: facebook ? facebook.name : "Link page",
            isConnected: !!facebook
          }}
          color="#3b5998"
          iconName="facebook-white-icon.svg"
          onClick={() => {
            if (facebook) disconnectFacebook();
            else connectToFacebook();
          }}
        />
        <PlatformCard
          item={{
            name: instagram ? instagram.username : "Link page",
            isConnected: !!instagram
          }}
          color="#ff826f"
          iconName="instagram-white-icon.svg"
          onClick={() => {
            if (instagram) disconnectInstagram();
            else connectToInstagram();
          }}
        />
        <div className="flex justify-between space-x-4">
          <WhatsappCard whatsUser={whatsapp} />
        </div>
      </div>
    </div>
  );
};
