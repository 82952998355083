import { useMutation } from "react-query";
import { SERVICES_TYPES, useInjection } from "@/ioc";
import { useAppDispatch } from "@/hooks";
import { useFacebookSDK } from "@/services/facebook-sdk";
import { authActions } from "@/store/reducers/auth.reducer";
import { IUserApi } from "@/api/user";
import {
  FacebookAuthResponse,
  FacebookUser,
  InstagramUser,
  User,
  WhatsappUser
} from "@/models/auth";
import { exposeToast } from "@/components/Toasts";

export const useFacebook = () => {
  const dispatch = useAppDispatch();
  const { facebookSDK } = useFacebookSDK();
  const userApi = useInjection<IUserApi>(SERVICES_TYPES.USER);
  const { mutate: connectToFacebook } = useMutation<FacebookUser, any, FacebookAuthResponse>(
    "facebook-auth",
    (values) => userApi.connectFacebook(values)
  );
  const connect = async () => {
    // eslint-disable-next-line
    const { status, authResponse }: fb.StatusResponse = await new Promise((resolve) => {
      facebookSDK?.login((res) => resolve(res), {
        scope: [
          "email",
          "public_profile",
          "pages_show_list",
          "pages_manage_metadata",
          "pages_messaging"
        ].join(","),
        return_scopes: true,
        enable_profile_selector: true
      });
    });

    if (status === "connected" && authResponse) {
      connectToFacebook(authResponse, {
        onSuccess(data) {
          dispatch(authActions.updateFacebookUser(data));
        }
      });
    }
  };

  return { connect };
};
export const useInstagram = () => {
  const dispatch = useAppDispatch();
  const { facebookSDK } = useFacebookSDK();
  const userApi = useInjection<IUserApi>(SERVICES_TYPES.USER);
  const { mutate: connectToInstagram } = useMutation<InstagramUser, any, FacebookAuthResponse>(
    "instagram-auth",
    (values) => userApi.connectInstagram(values)
  );
  const connect = async () => {
    // eslint-disable-next-line
    const { status, authResponse }: fb.StatusResponse = await new Promise((resolve) => {
      facebookSDK?.login((res) => resolve(res), {
        scope: [
          "email",
          "public_profile",
          "pages_show_list",
          "pages_messaging",
          "pages_manage_metadata",
          "instagram_basic",
          "instagram_manage_messages"
        ].join(","),
        return_scopes: true,
        enable_profile_selector: true
      });
    });
    if (status === "connected" && authResponse) {
      connectToInstagram(authResponse, {
        onSuccess(data) {
          dispatch(authActions.updateInstagramUser(data));
        }
      });
    }
  };

  return { connect };
};

export const useFacebookDisconnect = () => {
  const dispatch = useAppDispatch();

  const userApi = useInjection<IUserApi>(SERVICES_TYPES.USER);
  return useMutation("disconnectFa", () => userApi.disconnectFacebook(), {
    onSuccess() {
      dispatch(authActions.updateFacebookUser(null));
    }
  });
};

export const useInstagramDisconnect = () => {
  const dispatch = useAppDispatch();

  const userApi = useInjection<IUserApi>(SERVICES_TYPES.USER);
  return useMutation("disconnectIns", () => userApi.disconnectInstagram(), {
    onSuccess() {
      dispatch(authActions.updateInstagramUser(null));
    }
  });
};

export const useUpdateWhatsappUser = () => {
  const dispatch = useAppDispatch();

  const userApi = useInjection<IUserApi>(SERVICES_TYPES.USER);
  return useMutation<User, any, Partial<WhatsappUser>>(
    "updateWAUser",
    (user) => userApi.updateWhatsappUser(user),
    {
      onSuccess(data) {
        dispatch(authActions.updateUser(data));
        exposeToast({
          type: "success",
          message: "Your Whatsapp business information has been updated successfully"
        });
      }
    }
  );
};
