import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { ExampleSet } from "@/models/example-set";
import { BaseDialog } from "@/components/Modals";
import { TabController } from "@/components/Tab";
import { INodesDataTypes } from "@/models/flow";
import { ExampleSetForm } from "../DialogForms";
import { AssistantPromptForm } from "../DialogForms/AssistantPromptForm";

type _EditNodeDialogProps = {
  show: boolean;
  type?: string;
  form: JSX.Element;
  initialData: any;
  validationObject?: Record<string, yup.AnySchema>;
  sets: ExampleSet[];
  onFormSaved: (data: INodesDataTypes) => void;
  onCancel: () => void;
  onClose?: () => void;
  wrapperId?: string;
};

const Tab = ({ type, form, sets }: { type: string; form: JSX.Element; sets: ExampleSet[] }) =>
  type === "assistant" ? (
    <TabController
      items={[
        {
          order: 1,
          label: "Flow",
          element: <div className="p-[16px]">{form}</div>
        },
        {
          order: 2,
          label: "Prompt",
          element: (
            <div className="p-[16px]">
              <AssistantPromptForm />
            </div>
          )
        },
        {
          order: 3,
          label: "Example",
          element: (
            <div className="p-[16px]">
              <ExampleSetForm sets={sets} />
            </div>
          )
        }
      ]}
    />
  ) : (
    <TabController
      items={[
        {
          order: 1,
          label: "Flow",
          element: <div className="p-[16px]">{form}</div>
        },
        {
          order: 2,
          label: "Example",
          element: (
            <div className="p-[16px]">
              <ExampleSetForm sets={sets} />
            </div>
          )
        }
      ]}
    />
  );

export const EditNodeDialog = ({
  show,
  type,
  form,
  initialData,
  validationObject,
  sets,
  onFormSaved,
  onCancel,
  onClose,
  ...rest
}: _EditNodeDialogProps) => {
  const [visible, setVisible] = useState(show);

  const methods = useForm<INodesDataTypes>({
    defaultValues: {
      exampleSetMethod: "keep",
      exampleSetData: { name: "", sentences: [] },
      ...initialData
    },
    resolver: yupResolver(yup.object().shape({ ...validationObject }))
  });

  const { handleSubmit } = methods;

  const close = () => {
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
    setVisible(false);
  };

  useEffect(() => {
    if (show && !visible) setVisible(true);
  }, [show]);

  return (
    <BaseDialog
      title={`Editing a <<${type}>> node`}
      show={visible}
      body={
        <FormProvider {...methods}>
          <Tab form={form} sets={sets} type={type!} />
        </FormProvider>
      }
      action={
        <div className="flex justify-end items-center p-[15px] space-x-2">
          {/* eslint-disable-next-line */}
          <div
            role="button"
            className="text-gray-500 bg-white hover:bg-gray-100 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900"
            onClick={() => {
              onCancel();
              close();
            }}
          >
            Cancel
          </div>
          {/* eslint-disable-next-line */}
          <div
            role="button"
            className="text-white bg-[#044CAC] hover:bg-[#00328C] font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
            onClick={() => handleSubmit(onFormSaved)()}
          >
            Save
          </div>
        </div>
      }
      onClose={() => {
        close();
      }}
      {...rest}
    />
  );
};

export const editNodeDialog = ({
  type,
  form,
  initialData,
  validationObject,
  sets,
  save,
  cancel
}: {
  type?: string;
  validationObject?: Record<string, yup.AnySchema>;
  form: JSX.Element;
  initialData: any;
  sets: ExampleSet[];
  save: (data: INodesDataTypes) => void;
  cancel?: () => void;
}) => {
  const wrapper = document.body.appendChild(document.createElement("div"));
  wrapper.id = "edit-node-dialog";

  const root = createRoot(wrapper);

  const promise = new Promise<Boolean>((resolve) => {
    try {
      root.render(
        <EditNodeDialog
          show
          type={type}
          form={form}
          initialData={initialData}
          validationObject={validationObject}
          sets={sets}
          onFormSaved={(data) => {
            // eslint-disable-next-line no-unused-expressions
            save && save(data);
            resolve(true);
          }}
          onCancel={() => {
            // eslint-disable-next-line no-unused-expressions
            cancel && cancel();
            resolve(false);
          }}
          onClose={() => {
            // eslint-disable-next-line no-unused-expressions
            cancel && cancel();
            resolve(false);
          }}
          wrapperId={wrapper.id}
        />
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  });

  function dispose() {
    setTimeout(() => {
      root.unmount();
      setTimeout(() => {
        if (wrapper && wrapper.parentNode) {
          wrapper.parentNode.removeChild(wrapper);
        }
      });
    }, 600);
  }

  return promise.then(
    (result) => {
      dispose();
      return result;
    },
    (result) => {
      dispose();
      return Promise.reject(result);
    }
  );
};
